<template>
  <div class="local">
    <Nav></Nav>
    <div class="local-con">
      <div class="local-title">
        <div class="text">{{ localName }}目前共有稿件:{{ postCount }}</div>
        <div class="page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="20"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="downTotal"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div class="local-list">
        <div class="local-left">
          <div class="local-name" @click="onGoReset">余姚:</div>
          <div class="local-con-hefei">
            <div
              class="local-del"
              v-for="(k, shiIndex) in typeRootList"
              :key="k.id"
            >
              <div
                class="local-shi"
                @click="onclickshi(k.id, shiIndex, k.name)"
                :class="{ red: clickIndex == shiIndex }"
              >
                {{ k.name }}
              </div>
              <div class="loca-xian" v-if="k.dropShow">
                <div
                  class="xian-list"
                  v-for="(h, xianIndex) in k.xianList"
                  :key="h.id"
                  @click="onClickxian(h.id, xianIndex, h.name)"
                  :class="{ red: clickxian == xianIndex }"
                >
                  <div class="xian-name">
                    <span
                      class="el-icon-location-information"
                      style="margin-right:10px;"
                    ></span
                    >{{ h.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="local-name">安徽：</div>
          <div class="local-con-anhui">
            <div
              class="local-del-anhui"
              v-for="(k, shiIndex) in typeAnhuiList"
              :key="k.id"
            >
              <div
                class="local-shi-anhui"
                @click="onclickAnhuishi(k.id, shiIndex, k.name)"
                :class="{ active: clickAnhui == shiIndex }"
              >
                {{ k.name }}
              </div>
              <div class="loca-xian-anhui" v-if="k.dropShow">
                <div
                  class="xian-list-anhui"
                  v-for="(h, xianIndex) in k.anhuiXianList"
                  :key="h.id"
                  @click="onClickAnhuixian(h.id, xianIndex, h.name)"
                  :class="{ red: clickAnhuixian == xianIndex }"
                >
                  <div class="xian-name-anhui">
                    <span
                      class="el-icon-location-information"
                      style="margin-right:10px;"
                    ></span
                    >{{ h.name }}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="local-right">
          <div v-for="p in postList" :key="p.id" class="whole_box">
            <div class="whole_box_content">
              <div
                class="img"
                :style="{ backgroundImage: `url(` + p.imageURL + `)` }"
                @click="onLookPost(p.id)"
              ></div>
              <div class="title_s">
                {{ p.title }}
              </div>
              <div class="eventTime">{{ p.eventTime }}</div>
            </div>
          </div>
          <div class="page">
            <div class="page-container">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="20"
                :hide-on-single-page="true"
                layout="prev, pager, next, jumper"
                :total="downTotal"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function() {
    return {
      typeRootList: [], // 地址的root 余姚
      typeAnhuiList: [], // 安徽的root
      xianList: [], // 县级的list
      anhuiXianList: [], // 安徽下面的市
      defaultProps: {
        label: "name",
        children: "children"
      },
      postList: [], // 稿件列表
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postCount: "",
      query: {},
      dropShow: false, // 下拉加载展示
      clickId: "", // 被点击的ID
      clickIndex: -1, // 未被点击
      clickxian: -1,
      clickAnhui: -1,
      clickAnhuixian: -1,
      localName: "余姚地区",
      clickAnhuiId: 1,
      heFeiQuIndex: -1, // 余姚的一级index
      anhuiIndex: -1 // 安徽的一级index
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.addr()
        .id(10)
        .downAddress()
        .then(data => {
          data.forEach(k => {
            k.children = [];
            k.dropShow = false;
          });
          data.xianList = [];
          thiz.typeRootList = data;
        });

      RD.addr()
        .id(12)
        .downAddress()
        .then(data => {
          data.forEach(k => {
            k.children = [];
            k.dropShow = false;
          });
          data.anhuiXianList = [];
          thiz.typeAnhuiList = data;
        });

      thiz.query.addrId = 13;
      thiz.query.storeIds = [1];
      RD.pure()
        .query()
        .query(thiz.query, thiz.page, 10)
        .then(items => {
          items.list.forEach(i => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
          });
          thiz.postCount = items.pager.total;
          thiz.postList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
    },
    onGoReset() {
      this.reload();
    },
    onclickshi(id, shiIndex, name) {
      var thiz = this;
      this.clickIndex = shiIndex;
      this.heFeiQuIndex = shiIndex;
      this.clickxian = -1;
      this.clickAnhui = -1;
      this.clickAnhuixian = -1;
      this.clickId = id;
      this.localName = name;
      if (this.anhuiIndex >= 0) {
        thiz.typeAnhuiList[this.anhuiIndex].dropShow = false;
      }

      this.typeRootList.forEach((k, index) => {
        if (k.dropShow) {
          k.dropShow = false;
        } else {
          k.dropShow = shiIndex == index;
        }
      });

      RD.pure()
        .addr()
        .id(id)
        .downAddress()
        .then(items => {
          this.typeRootList[shiIndex].xianList = items;
          this.queryPost(id);
        });
    },
    onClickxian(id, index, name) {
      this.clickId = id;
      this.clickxian = index;
      this.clickIndex = -1;
      this.clickAnhui = -1;
      this.clickAnhuixian = -1;
      this.localName = name;
      this.queryPost(id);
    },
    queryPost(id) {
      var thiz = this;
      var post = {};
      post.addrId = id;
      post.storeIds = [1];
      RD.pure()
        .query()
        .query(post, thiz.page, 10)
        .then(items => {
          items.list.forEach(i => {
            i.imageURL = RD.pic().id(i.frontPicId).mImage_URL();
          });
          thiz.postCount = items.pager.total;
          thiz.postList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
    },
    handleCurrentChange(val) {
      var thiz = this;
      thiz.page = val;
      thiz.currentPage = val;
      thiz.queryPost(thiz.clickId);
    },
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 点击安徽的下级
    onclickAnhuishi(id, shiIndex, name) {
      this.clickAnhui = shiIndex;
      this.anhuiIndex = shiIndex;
      this.clickIndex = -1;
      this.clickxian = -1;
      this.clickAnhuixian = -1;
      this.clickAnhuiId = id;
      this.localName = name;
      if (this.heFeiQuIndex >= 0) {
        this.typeRootList[this.heFeiQuIndex].dropShow = false;
      }

      this.typeAnhuiList.forEach((k, index) => {
        if (k.dropShow) {
          k.dropShow = false;
        } else {
          k.dropShow = shiIndex == index;
        }
      });

      RD.pure()
        .addr()
        .id(id)
        .downAddress()
        .then(items => {
          this.typeAnhuiList[shiIndex].anhuiXianList = items;
          this.queryPost(id);
        });
    },
    // 点击安徽的
    onClickAnhuixian(id, index, name) {
      this.clickAnhuiId = id;
      this.clickAnhuixian = index;
      this.clickAnhui = -1;
      this.clickIndex = -1;
      this.clickxian = -1;
      this.localName = name;
      this.queryPost(id);
    }
  }
};
</script>

<style scoped lang="less">
.local {
  height: auto;
  background-color: #f1f1f1;
  .local-con {
    width: 1200px;
    margin: 0 auto;
    height: auto;
    .local-title {
      height: 50px;
      display: flex;
      position: relative;
      .text {
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        font-size: 1.3rem;
      }
      .page {
        position: absolute;
        right: 30px;
        top: 10px;
      }
    }
    .local-list {
      display: flex;
      height: auto;
      .local-left {
        width: 25%;
        border: 1px solid #ccc;
        .local-name {
          font-size: 1.8rem;
          text-align: left;
          height: 35px;
          margin-left: 5px;
        }
        .local-con-hefei {
          height: auto;
          width: 90%;
          margin-left: 20px;
          font-size: 1.5rem;
          .local-del {
            height: auto;
            width: 100%;
            .local-shi {
              height: 30px;
              text-align: left;
              cursor: pointer;
            }
            .local-shi.red {
              color: red;
            }
            .local-shi.active {
              color: red;
            }
            .loca-xian {
              height: auto;
              margin-left: 20px;
              text-align: left;
              .xian-list {
                height: 30px;
                cursor: pointer;
              }
              .xian-list.red {
                color: red;
              }
            }
          }
        }
        .local-con-anhui {
          height: auto;
          width: 90%;
          margin-left: 20px;
          font-size: 1.5rem;
          .local-del-anhui {
            height: auto;
            width: 100%;
            .local-shi-anhui {
              height: 30px;
              text-align: left;
              cursor: pointer;
            }
            .local-shi-anhui.active {
              color: red;
            }
            .loca-xian-anhui {
              height: auto;
              margin-left: 20px;
              text-align: left;
              .xian-list-anhui {
                height: 30px;
                cursor: pointer;
              }
              .xian-list-anhui.red {
                color: red;
              }
            }
          }
        }
      }
      .local-right {
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        .whole_box {
          height: 0;
          box-sizing: border-box;
          margin: 10px;
          position: relative;
          box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
          background-color: #f1f1f1;
          overflow: hidden;
          .whole_box_content {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            .img {
              width: 100%;
              height: 75%;
              transition: all 0.6s;
              display: block;
              z-index: 10;
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              background-color: #f6f6f6;
              cursor: pointer;
            }
            .title_s {
              height: 8%;
              transition: all 0.5s linear;
              width: 90%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding-top: 15px;
              text-align: left;
              font-size: 1.4rem;
              left: 0;
              right: 0;
              margin-left: 10px;
              line-height: 25px;
              cursor: pointer;
            }
            .eventTime {
              height: 30px;
              line-height: 30px;
              text-align: left;
              margin-left: 10px;
            }
            .time {
              height: 10%;
              position: absolute;
              left: 135px;
              .count {
                height: 20px;
                display: inline-block;
              }
              .tag {
                display: inline-block;
                height: 20px;
                width: 20px;
                cursor: pointer;
                margin-left: 10px;
                background: no-repeat url(../assets/tagup.png);
              }
              .like {
                height: 20px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px;
                width: 20px;
                background: no-repeat url(../assets/like.png);
              }
              .com {
                height: 20px;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px;
                width: 20px;
                background: no-repeat url(../assets/comment.png);
              }
            }
          }
        }
        .page {
          position: relative;
          height: 60px;
          width: 100%;
          .page-container {
            position: absolute;
            bottom: 10px;
            right: 20px;
          }
        }
      }
    }
  }
}
.el-tree {
  height: 600px;
  .el-tree-node {
    height: 80px !important;
    display: inline-block;
  }
}
.addr {
  display: inline-block;
  font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
  .whole_box {
    width: calc(100% - 10px);
    padding-top: calc((100% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 600px) {
  .whole_box {
    width: calc(100% - 10px);
    padding-top: calc((100% - 20px) * 1.35);
  }
}

@media only screen and (min-width: 960px) {
  .whole_box {
    width: calc(30% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}

@media only screen and (min-width: 1200px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
@media only screen and (min-width: 1600px) {
  .whole_box {
    width: calc(50% - 20px);
    padding-top: calc((25% - 20px) * 1.35);
  }
}
</style>
